import React from "react"

class Comments extends React.Component {
  componentDidMount() {
    // make sure the id is yours
    window.gc_params = {
      graphcomment_id: 'ByJazz',
      // if your website has a fixed header, indicate it's height in pixels
      fixed_header_height: 0,
    };

    /* - - - DON'T EDIT BELOW THIS LINE - - - */


    (function() {
      var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
      gc.src = 'https://graphcomment.com/js/integration.js?' + Math.round(Math.random() * 1e8);
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
    })();
  }

  render() {
    return (
      <div id="graphcomment"></div>
    )
  }
}

export default Comments
